<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-tabs
                        v-model="stockReportsCurrentTab"
                        color="success"
                        background-color="grey lighten-2"
                        slider-color="appic-green"
                    >
                        <v-tab key="priceList">{{ $t('message.priceList') }}</v-tab>
                        <v-tab key="incomingStock">{{ $t('message.incomingStock') }}</v-tab>
                        <v-tab key="weeklyWarehouseSales">{{ $t('message.weeklySales') }}</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="stockReportsCurrentTab">
                        <v-tab-item key="price-list">
                            <v-card flat>
                                <AvailableList/>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="incoming-stock">
                            <v-card flat>
                                <IncomingStockListV2/>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="weekly-warehouse-sales">
                            <v-card flat>
                                <WeeklySalesListV2/>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
const AvailableList = () => import("Components/Appic/StockReports/AvailableList");
const WeeklySalesListV2 = () => import("Components/Appic/StockReports/WeeklySalesListV2");
const IncomingStockListV2 = () => import("Components/Appic/StockReports/IncomingStockListV2");
export default {
    name: "StockReports",
    components: {IncomingStockListV2, WeeklySalesListV2, AvailableList},
    data() {
        return {
            editDialog: false,
            loader: false,
            loading: {
                save: false,
                settings: false
            },
        }
    },
    computed: {
        ...mapFields('runtime', {
            stockReportsCurrentTab: 'stockReportsCurrentTab'
        })
    },
    mounted () {
        if(this.stockReportsCurrentTab == null) this.stockReportsCurrentTab = 0
    }
}
</script>

<style scoped>

</style>